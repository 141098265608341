<template>
  <!--begin:dresscodesIndex-->
  <div class="row gy-5 g-xl-8">
    <DresscodesList widget-classes="card-xxl-stretch mb-5 mb-xl-8"></DresscodesList>
  </div>
  <!--end::dresscodesIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import DresscodesList from "@/components/dresscodes/DresscodesList.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "DresscodesView",
  components: {
    DresscodesList
  },
  mounted() {
    setCurrentPageTitle("Dresscodes");
  }
});
</script>
