<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px">ID</th>
          <th class="min-w-100px">Name</th>
          <th class="min-w-120px text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dresscode in tableData" :key="dresscode.id">
          <td>
            <span class="text-dark fw-bolder text-hover-primary d-block fs-6">{{
              dresscode.id
            }}</span>
          </td>
          <td>
            <router-link
              :to="'/dresscodes/' + dresscode.id"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ dresscode.name }}</router-link
            >
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <button
                @click="emitEditEvent(dresscode)"
                type="button"
                class="btn btn-sm btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#DresscodeModal"
              >
                Edit
              </button>
              <button
                @click="openDeleteModal(dresscode)"
                data-bs-target="#deleteModal"
                data-bs-toggle="modal"
                class="btn btn-sm btn-danger"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DresscodesDataTable",
  props: {
    tableData: Object
  },
  methods: {
    emitEditEvent(dresscode) {
      this.$emit("editModalOpened", dresscode);
    },
    openDeleteModal(dresscode) {
      this.$emit("deleteModalOpened", dresscode);
    }
  }
});
</script>
