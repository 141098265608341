<template>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <DressCodeModal
        v-show="isThereAModalActive"
        :currentlySelectedDresscode="currentlySelectedDresscode"
        :title="modalSetup.title"
        :action="modalSetup.action"
        @saveChanges="saveDataFromModal"
    ></DressCodeModal>
    <DeleteModal
      v-show="isThereAModalActive"
      @delete="deleteSelectedDresscode"
      :currentlySelectedEntry="this.currentlySelectedDresscode.name"
    ></DeleteModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Dress Codes</span>

          <span class="text-muted mt-1 fw-bold fs-7">List of Dress Codes</span>
        </h3>
        <div
          class="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a dress code"
        >
          <a
            href="#"
            @click.prevent="openAddNewModal"
            class="btn btn-sm btn-success"
            data-bs-toggle="modal"
            data-bs-target="#DresscodeModal"
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotone/Code/Plus.svg" />
            </span>
            Add new Dress Code
          </a>
        </div>
      </div>
      <div class="card-body py-3">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
            v-else-if="dresscodesList.length == 0"
            :description="
          'OOPS... Unfortunately no dresscodes were found.'
        "
        ></el-empty>
        <DresscodesDataTable
          v-else
          :tableData="dresscodesList"
          @editModalOpened="openEditModal"
          @deleteModalOpened="openDeleteModal"
        ></DresscodesDataTable>
      </div>
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DressCodeModal from "@/components/settings/modals/DressCodeModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import DresscodesDataTable from "@/components/dresscodes/DresscodesDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";

export default defineComponent({
  name: "DresscodesList",
  components: {
    DresscodesDataTable,
    DressCodeModal,
    DeleteModal,
    Pagination
  },
  data() {
    return {
      currentlySelectedDresscode: {
        id: null,
        name: null
      },
      isThereAModalActive: false,
      modalSetup: {
        action: "",
        title: ""
      },
      pagination: {}
    };
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("DresscodesModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData",
      "setPageNumber",
      "resetPageNumber",
      "resetState"
    ]),
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openAddNewModal() {
      this.resetDresscodeData();
      this.modalSetup.title = "";
      this.$nextTick(() => this.modalSetup.title = "Add new Dress Code");
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedDresscode.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openEditModal(dresscode) {
      this.modalSetup.title = "Edit Dress Code";
      this.modalSetup.action = "edit";
      this.currentlySelectedDresscode = dresscode;
    },
    cancelModal() {
      this.isThereAModalActive = false;
      this.modalSetup = {
        action: "",
        title: ""
      };
    },
    openDeleteModal(entry) {
      this.currentlySelectedDresscode = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedDresscode() {
      this.destroyData(this.currentlySelectedDresscode.id);
      this.isThereAModalActive = false;
      this.currentlySelectedDresscode.id = null;
    },
    resetDresscodeData() {
      this.currentlySelectedDresscode = {
        id: null,
        name: null
      };
    }
  },
  computed: {
    ...mapGetters("DresscodesModule", [
      "dresscodesList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>

<style>
.spinner-size-increase {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
