<template>
  <!--begin::Modal-->
  <div
      class="modal fade"
      ref="DresscodeModal"
      id="DresscodeModal"
      tabindex="-1"
      aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
            class="form"
            ref="DresscodeModalForm"
            id="DresscodeModal_form"
            :validation-schema="validationSchema"
            @submit="submit"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="DresscodeModal_header">
            <!--begin::Modal title-->
            <h2>{{ title }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
                class="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
                class="scroll-y me-n7 pe-7"
                id="DresscodeModal_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#DresscodeModal_header"
                data-kt-scroll-wrappers="#DresscodeModal_scroll"
                data-kt-scroll-offset="300px"
            >
              <div class="row">
                <div class="col-md-12 fv-row">
                  <!--begin::Label-->
                  <label for="name" class="required fs-5 fw-bold mb-2">Name</label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <Field
                      type="text"
                      id="name"
                      class="form-control form-control-solid"
                      placeholder="Enter name"
                      name="name"
                      v-model.trim="dresscodeData.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
                type="reset"
                id="DresscodeModal_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
            >
              Discard
            </button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
                :disabled="dresscodeData.name == ''"
                type="submit"
                id="DresscodeModal_submit"
                class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>

              <span class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>

  <!--end::Modal - New Address-->
</template>

<script lang="js">
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';

function initialDresscodeData() {
  return {
    name: ""
  }};

export default defineComponent({
  name: "DresscodeModal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    currentlySelectedDresscode: Object,
    action : String,
    title : String
  },
  mounted() {
    this.modal = new Modal(this.$refs.DresscodeModal)
  },
  data() {
    return {
      validationSchema: Yup.object().shape({
        name: Yup.string()
            .required()
            .label("Name")
      }),
      dresscodeData: initialDresscodeData(),
      modal: null
    };
  },
  methods: {
    submit() {
      this.validationSchema.isValid(this.dresscodeData).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$emit("saveChanges", {payload : this.dresscodeData, action : this.action});
        }
      })
    },
  },
  watch: {
    currentlySelectedDresscode() {
      if(this.action == "edit" ){
        this.dresscodeData.name = this.currentlySelectedDresscode.name
      }
    },
    title() {
      if(this.action !== "edit") this.dresscodeData = initialDresscodeData();
      this.$refs.DresscodeModalForm.resetForm(null);
    }
  }
});
</script>
